import React from 'react';
import classNames from 'classnames';
import { useToggle } from '../hooks/toggle';
import { withUnmountWhenHidden } from './hocs';
import Anchor from './Anchor';
import { CtrlAssistDropdown, newDropdownUI } from './Dropdown';
// import { CtrlKnowledgeBasePopup } from '../../components/v5/KnowledgeBasePopup'; // TODO: NOTE.1 convert this to react component

const baseClassName = "c3-assist"
	, idNameField = {id: "Id", value: "Name", content: "Content"}
	, idSuggestNameField = {id: "id", value: "subject", content: "answer"}
	, capitalizeStyle = {textTransform: "capitalize"}
	;
const AssistBase = props => <div className={classNames(baseClassName, 'reply-links-line')} {...props} />;

const Assist = withUnmountWhenHidden(AssistBase);

const AssistReplyLinkLabel = ({ children, style }) => <div className="label reply-links-label" style={style}>{children}</div>;

const AssistColumnBase = ({ className, children }) => (
	<div className={classNames(className, {"reply-links-column": !className})}>
		{children}
	</div>
);

export const AssistColumn = withUnmountWhenHidden(AssistColumnBase);

export const AssistBarLabel = ({ textCase }) => {
	let style, text;
	// TODO: use CSS text-transform as now need to maintain i18n.
	if (textCase === "capitalize") {
		text = I('assist:');
		style = capitalizeStyle;
	} else {
		text = I('ASSIST:');
	}
	return <AssistReplyLinkLabel style={style}>{text}</AssistReplyLinkLabel>;
};

export const KnowledgeBaseLabel = props => <AssistReplyLinkLabel>{I('KNOWLEDGE BASE:')}</AssistReplyLinkLabel>;

export const AssistBarItems = ({ children }) => <ul className="reply-links">{children}</ul>;

const AssistBarOneItemBase = ({ children }) => <li>{children}</li>;

export const AssistBarOneItem = withUnmountWhenHidden(AssistBarOneItemBase);

export function withIdAndTitle(Component, id, title) {
	return props => <Component id={id} title={L(title)} {...props} />;
}

// const KnowledgeBaseAssist = withIdAndTitle(CtrlKnowledgeBasePopup, "errandKnowledgeBase", I("Open Knowledge base")); // TODO: see NOTE.1

// enable component to have internal state of propName and use
// toggleCallbackName to toggle the state and actionCallback will also switch
// off the state after it comlete executes.
const newCreateToggleableWithActionOff = (
	Component,
	propName,
	toggleCallbackName
) => props => {
	const [show, onToggle] = useToggle()
	const { ...p } = props
	p[propName] = show
	p[toggleCallbackName] = onToggle
	return <Component {...p} />
}

function oldCreateToggleableWithActionOff(
	Component
	, propName
	, toggleCallbackName
	, actionCallback
) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				show: false
			};
			this.handleAction = this.handleAction.bind(this);
			this.handleToggle = this.handleToggle.bind(this);
		}
		handleAction(...args) {
			this.props[actionCallback](...args);
			this.setState({show: false});
		}
		handleToggle(e) {
			this.setState({show: !this.state.show});
		}
		render() {
			const { ...props } = this.props;
			props[actionCallback] = this.handleAction;
			props[toggleCallbackName] = this.handleToggle;
			props[propName] = this.state.show;
			return <Component {...props} />;
		}
	};
}

const createToggleableWithActionOff = newDropdownUI ?
	newCreateToggleableWithActionOff : oldCreateToggleableWithActionOff

function createOpenAndOnToggle(Component, actionCallback) {
	return createToggleableWithActionOff(
		Component
		, "open"
		, "onToggle"
		, actionCallback
	);
}

function withToggleableAndOnSelectItemOff(Component) {
	return createOpenAndOnToggle(Component, "onSelectItem");
}

const AssistDropdownWithToggle = withToggleableAndOnSelectItemOff(CtrlAssistDropdown);

export const SalutationAssist = withIdAndTitle(AssistDropdownWithToggle, "salutations", I("Salutation"));

export const SignatureAssist = withIdAndTitle(AssistDropdownWithToggle, "signature", I("Signature"));

const AssistDropdownWithToggleAndOnAppendItem = createOpenAndOnToggle(CtrlAssistDropdown, "onAppendItem");

const TemplateAssistBase = withIdAndTitle(AssistDropdownWithToggleAndOnAppendItem, "templates", I("Templates"));

export const TemplateAssist = ({ id, ...props }) => (
	<TemplateAssistBase
		id={typeof id !== "undefined" ? id : "errand-temp-assist"}
		appendAction={true}
		idFields={idNameField}
		customClass="templates"
		{...props}
	/>
);

const QuickReplyAssistBase = withIdAndTitle(AssistDropdownWithToggleAndOnAppendItem, "quickReplies", I("Quick Reply"));
export const QuickReplyAssist = ({ id, ...props }) => (
	<QuickReplyAssistBase
		id={typeof id !== "undefined" ? id : "errand-qr-assist"}
		appendAction={true}
		idFields={idNameField}
		customClass="quickreplies"
		{...props}
	/>
);

const createSimpleAnchorAssist = text => props => <Anchor {...props}>{text}</Anchor>;

export const PreviewAssist = createSimpleAnchorAssist(I("Preview"));
export const PreviewAssistHideText = createSimpleAnchorAssist("");

export const ProtectAssist = createSimpleAnchorAssist(I("Protected reply content"));

const SuggestionAssistBase = withIdAndTitle(AssistDropdownWithToggleAndOnAppendItem, "suggestion", I("Suggestion"));

export const SuggestionAssist = ({ id, ...props }) => (
	<SuggestionAssistBase
		id={typeof id !== "undefined" ? id : "errand-suggest-assist"}
		direction="up"
		appendAction={true}
		idFields={idSuggestNameField}
		customClass="templates"
		{...props}
	/>
);

export default Assist;
